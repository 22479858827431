import React from 'react';
import { PiUserCircle } from 'react-icons/pi';

const Avatar = ({ imageUrl, name, width, height, isOnline, bgColor = ['#ccc'], randomNumber = 0 }) => {
  const avatarName = name ? name[0].toUpperCase() : ''; 

  return (
    <div
      className="text-slate-800 rounded-full font-bold relative"
      style={{
        width: `${width}px`,
        height: `${height}px`,
        borderRadius: '50%', // Ensure it's a circle
        overflow: 'hidden'  // Hide overflow
      }}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt={name}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover', // Ensure image covers the circle
            borderRadius: '50%' // Ensure image is clipped to a circle
          }}
        />
      ) : name ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '50%', // Ensure background div is a circle
            backgroundColor: bgColor[randomNumber] || '#ccc', // Ensure bgColor is safe to access
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            color: 'white',
            fontSize: '1.25rem' // Adjust size as needed
          }}
        >
          {avatarName}
        </div>
      ) : (
        <PiUserCircle
          size={width}
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '50%' // Ensure icon is clipped to a circle
          }}
        />
      )}

      {isOnline && (
        <div
          className='bg-green-600 p-1 absolute bottom-2 -right-1 z-10 rounded-full'
          style={{
            width: '20px', // Adjust size as needed
            height: '20px', // Adjust size as needed
            borderRadius: '50%' // Ensure it's a circle
          }}
        />
      )}
    </div>
  );
};

export default Avatar;
