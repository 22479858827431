import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { PiUserCircle } from "react-icons/pi";

const CheckUsernamePage = () => {
  const [username, setUsername] = useState("");
  const navigate = useNavigate();

  const handleOnChange = (e) => {
    setUsername(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const URL = `${process.env.REACT_APP_BACKEND_URL}/api/username`;
    
    try {
      const response = await axios.post(URL, { username });
      toast.success(response.data.message);

      console.log(username, response.data.data.username);

      if (response.data.success) {
        setUsername("");
        navigate('/password', {
          state: {
            id: response.data.data.id,
            role: response.data.data.role,
            name: response.data.data.name,
            profile_pic: response.data.data.profile_pic,
            username: response.data.data.username
          },
        });
      }
    } catch (error) {
      console.error("Error response:", error.response);
      if (error.response) {
        toast.error(`Error: ${error.response.data.message || "An error occurred"}`);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  return (
    <div className='mt-14'>
      <div className='bg-white w-full max-w-md rounded overflow-hidden p-6 mx-auto'>
        <div className='w-fit mx-auto mb-2'>
          <PiUserCircle size={80} />
        </div>
        <h3 className='text-center text-2xl'>Welcome to Hance Chat App!</h3>
        <form className='grid gap-4 mt-3' onSubmit={handleSubmit}>
          <div className='flex flex-col gap-1'>
            <label htmlFor='username'>Username:</label>
            <input
              type='text'
              id='username'
              name='username'
              placeholder='Enter your username'
              className='bg-slate-100 px-2 py-1 focus:outline-primary'
              value={username}
              onChange={handleOnChange}
              required
            />
          </div>
          <button
            type='submit'
            className='bg-primary text-lg px-4 py-1 hover:bg-secondary rounded mt-2 font-bold text-white leading-relaxed tracking-wide'
          >
            Let's Go
          </button>
        </form>
        <p className='my-3 text-center'>
          New User? <Link to={"/register"} className='hover:text-primary font-semibold'>Register</Link>
        </p>
      </div>
    </div>
  );
}

export default CheckUsernamePage;
