import React from 'react'

const Divider = () => {
  return (
    <div className='p-[0.5px] bg-slate-200 my-1'>
      
    </div>
  )
}

export default Divider
