import React, { useState } from 'react';
import { IoClose } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import uploadFile from '../helpers/uploadFile';
import axios from 'axios';
import toast from 'react-hot-toast';

const RegisterPage = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    username: "",
    password: "",
    profile_pic: "",
    role: "" // Added role field
  });
  const [uploadPhoto, setUploadPhoto] = useState("");
  const navigate = useNavigate();

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleUploadPhoto = async (e) => {
    const file = e.target.files[0];

    const uploadPhoto = await uploadFile(file);

    setUploadPhoto(file);

    setData((prev) => ({
      ...prev,
      profile_pic: uploadPhoto?.url
    }));
  };

  const handleClearUploadPhoto = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setUploadPhoto(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Validate username
    const usernamePattern = /^[a-zA-Z0-9_]+$/; // Allows letters, numbers, and underscores
    if (!usernamePattern.test(data.username)) {
      return toast.error('Username must only contain letters, numbers, and underscores');
    }

    const URL = `${process.env.REACT_APP_BACKEND_URL}/api/register`;

    try {
      const response = await axios.post(URL, data);
      console.log('hhhh')
      toast.success(response.data.message);

      
      console.log(response.data);

      if (response.data.success) {
        setData({
          name: "",
          email: "",
          username: "",
          password: "",
          profile_pic: "",
          role: "" // Reset role field
        });

        navigate('/email');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || 'An error occurred');
    }
  };

  return (
    <div className='mt-5'>
      <div className='bg-white w-full max-w-md rounded overflow-hidden p-4 mx-auto'>
        <h2 className='flex justify-center font-semibold text-xl'> Create your account to get started!</h2>

        <form className='grid gap-4 mt-5' onSubmit={handleSubmit}>
          <div className='flex flex-col gap-1'>
            <label htmlFor='name'>Name :</label>
            <input
              type='text'
              id='name'
              name='name'
              placeholder='Enter your name'
              className='bg-slate-100 px-2 py-1 focus:outline-primary'
              value={data.name}
              onChange={handleOnChange}
              required
            />
          </div>

          <div className='flex flex-col gap-1'>
            <label htmlFor='email'>Email :</label>
            <input
              type='email'
              id='email'
              name='email'
              placeholder='Enter your email'
              className='bg-slate-100 px-2 py-1 focus:outline-primary'
              value={data.email}
              onChange={handleOnChange}
              required
            />
          </div>

          <div className='flex flex-col gap-1'>
            <label htmlFor='username'>Username :</label>
            <input
              type='text'
              id='username'
              name='username'
              placeholder='Enter your username'
              className='bg-slate-100 px-2 py-1 focus:outline-primary'
              value={data.username}
              onChange={handleOnChange}
              required
            />
          </div>

          <div className='flex flex-col gap-1'>
            <label htmlFor='password'>Password :</label>
            <input
              type='password'
              id='password'
              name='password'
              placeholder='Enter your password'
              className='bg-slate-100 px-2 py-1 focus:outline-primary'
              value={data.password}
              onChange={handleOnChange}
              required
              autoComplete='new-password'
            />
          </div>

          <div className='flex flex-col gap-1'>
            <label htmlFor='role'>Role :</label>
            <select
              id='role'
              name='role'
              className='bg-slate-100 px-2 py-1 focus:outline-primary'
              value={data.role}
              onChange={handleOnChange}
              required
            >
               <option value=""></option>
              <option value="Marketing">Marketing</option>
              <option value="Sales">Sales</option>
              <option value="Development">Development</option>
              <option value="R&D">R&D</option>
            </select>
          </div>

          <div className='flex flex-col gap-1'>
            <label htmlFor='profile_pic'>Photo :
              <div className='h-14 bg-slate-200 flex justify-center items-center border rounded hover:border-primary cursor-pointer'>
                <p className='text-sm max-w-[300px] text-ellipsis line-clamp-1'>
                  {uploadPhoto?.name ? uploadPhoto?.name : "Upload profile photo"}
                </p>
                {uploadPhoto?.name && (
                  <button className='text-lg ml-2 hover:text-red-600' onClick={handleClearUploadPhoto} aria-label="Clear Photo Upload">
                  <IoClose />
                </button>
                
                )}
              </div>
            </label>
            <input
              type='file'
              id='profile_pic'
              name='profile_pic'
              className='bg-slate-100 px-2 py-1 focus:outline-primary hidden'
              onChange={handleUploadPhoto}
            />
          </div>

          <button
            className='bg-primary text-lg px-4 py-1 hover:bg-secondary rounded mt-2 font-bold text-white leading-relaxed tracking-wide'
          >
            Register
          </button>
        </form>

        <p className='my-3 text-center'>Already have an account? <Link to={"/email"} className='hover:text-primary font-semibold'>Login</Link></p>
      </div>
    </div>
  );
}

export default RegisterPage;
